import React, { useEffect, useMemo, useState } from 'react';
import {
  CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
);

function LineGraph({ data }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
    options: {
      scales: {
        y: {
          suggestedMin: 0,
          suggestedMax: 1000,
        },
      },
    },
  });

  useEffect(() => {
    setChartData({
      labels: data.map((item) => item.date),
      datasets: [
        {
          label: 'Capital Gains',
          data: data.map((item) => item.value),
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          tension: 0.4,
        },
      ],
    });
  }, [data]);

  const maxYValue = useMemo(() => {
    let v = 0;
    chartData.datasets.forEach((dataset) => {
      const max = Math.max(...dataset.data);
      if (max > v) {
        v = max;
      }
    });

    return v;
  }, [chartData]);

  const options = useMemo(() => (
    {
      scales: {
        y: {
          min: 0,
          max: Math.ceil(maxYValue / 1000) * 1000,
          ticks: {
            callback(value) {
              return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
            },
          },
        },
      },
    }
  ), [chartData]);

  return (
    <div>
      <Line
        data={chartData}
        options={options}
      />
    </div>
  );
}

export default LineGraph;
