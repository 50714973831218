import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTrades } from '../contexts/TradesContext';
import UserTrades from './UserTrades';
import { useUsers } from '../contexts/UsersContext';

function DashboardUser() {
  const { trades } = useTrades();
  const { currentUser } = useUsers();

  const totalGrossProfit = trades.reduce(
    (total, trade) => total + trade.gross_profit,
    0,
  );

  const dailyProfitTarget = 5000;

  const navigate = useNavigate();
  const goToAddTrade = () => {
    navigate('/add-trade');
  };

  return (
    <Box sx={{ p: 5 }}>
      <Button variant="contained" onClick={goToAddTrade}>
        Add trade
      </Button>

      <UserTrades user={currentUser} trades={trades} />

      <Box sx={{ mt: 2 }}>
        <p>
          Accumulated Profit: $
          {totalGrossProfit.toFixed(2)}
          {' '}
          / Daily Profit Target: $
          {dailyProfitTarget.toFixed(2)}
        </p>
      </Box>
    </Box>
  );
}

export default DashboardUser;
