import React from 'react';
import {
  Button,
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

function Header() {
  const {
    currentUser, userData, signIn, signOut,
  } = useAuth();

  return (
    <header>
      <h6>
        {userData ? (
          `Hi ${userData.given_name}!`
        ) : (
          'AMC Traders'
        )}
      </h6>
      {currentUser ? (
        <Button color="inherit" onClick={signOut}>
          Sign Out
        </Button>
      ) : (
        <Button color="inherit" onClick={signIn}>
          Sign In
        </Button>
      )}
    </header>
  );
}

export default Header;
