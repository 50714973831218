import {
  createContext, useContext, useMemo, useState,
} from 'react';
import axios from 'axios';

const IGContext = createContext();

export const useIG = () => useContext(IGContext);

const baseURL = 'https://demo-api.ig.com/gateway';

export function IGProvider({ children }) {
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiKey = 'your_api_key';

  const getAccessToken = async (username, password) => {
    setLoading(true);

    const response = await axios.post(`${baseURL}/deal/session`, null, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json; charset=UTF-8',
        VERSION: '2',
        'X-IG-API-KEY': apiKey,
      },
      data: {
        identifier: username,
        password,
      },
    });

    setAccessToken(response.data.oauthToken.access_token);
    setLoading(false);
  };

  const getOrdersHistory = async () => {
    setLoading(true);

    const response = await axios.get(`${baseURL}/deal/history/orders`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json; charset=UTF-8',
        VERSION: '1',
        'X-IG-API-KEY': apiKey,
        Authorization: `Bearer ${accessToken}`,
      },
    });

    setLoading(false);

    return response.data;
  };

  const value = useMemo(
    () => ({
      getAccessToken,
      getOrdersHistory,
      loading,
    }),
    [getAccessToken, getOrdersHistory, loading],
  );

  return (
    <IGContext.Provider value={value}>
      {children}
    </IGContext.Provider>
  );
}
