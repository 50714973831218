export const getBusinessDatesCount = (startDate, endDate) => {
  let count = 0;
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) count += 1;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
};
