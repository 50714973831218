import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import dayjs from 'dayjs';
import {
  doc, getDoc, setDoc, updateDoc,
} from 'firebase/firestore';
import { getAdditionalUserInfo } from 'firebase/auth';
import {
  auth, db, signInWithGoogle, signOut as _signOut,
} from '../firebase';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  const signIn = () => {
    signInWithGoogle().then((data) => {
      if (!data) return;

      const { user } = data;
      const { isNewUser, profile } = getAdditionalUserInfo(data);

      const props = {
        email: user.email,
        display_name: user.displayName,
        given_name: profile.given_name,
        family_name: profile.family_name,
        last_login_at: dayjs(user.metadata.lastLoginAt).toDate(),
      };

      if (isNewUser) {
        setDoc(
          doc(db, 'users', user.uid),
          {
            ...props,
            created_at: dayjs(user.metadata.createdAt).toDate(),
            role: 1,
            commission_percentage: 10,
            daily_goal: 5000,
          },
        ).then();
      } else {
        updateDoc(
          doc(db, 'users', user.uid),
          {
            ...props,
          },
        ).then();
      }
    });
  };

  const signOut = () => _signOut();

  useEffect(() => auth.onAuthStateChanged((user) => {
    setLoading(true);
    setCurrentUser(user);
    setUserData(null);

    if (user) {
      getDoc(
        doc(db, 'users', user.uid),
      )
        .then((d) => {
          setUserData(d.data());
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }), []);

  const isAdmin = useMemo(() => userData && userData.role === 2, [userData]);

  const value = useMemo(
    () => ({
      currentUser,
      userData,
      loading,
      signIn,
      signOut,
      isAdmin,
    }),
    [currentUser, userData, loading, signIn, signOut, isAdmin],
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
