import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuth } from '../contexts/AuthContext';

import Header from './Header';
import TradeForm from './TradeForm';
import DashboardAdmin from './DashboardAdmin';
import DashboardUser from './DashboardUser';
import StockExchangeMap from './StockExchangeMap';

function App() {
  const {
    currentUser,
    isAdmin,
    loading,
  } = useAuth();

  const content = () => {
    if (loading) return (<span>Loading...</span>);

    if (currentUser) {
      return (
        <Routes>
          <Route
            path="/"
            element={isAdmin ? <DashboardAdmin /> : <DashboardUser />}
          />
          <Route
            path="/add-trade"
            exact
            element={<TradeForm />}
          />
          <Route
            path="/map"
            exact
            element={<StockExchangeMap full />}
          />
        </Routes>
      );
    }

    return (<p>Please sign in to use the app.</p>);
  };

  return (
    <div>
      <Header />
      {content()}
    </div>
  );
}

export default App;
