import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  collection, onSnapshot,
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from './AuthContext';

const UsersContext = createContext();

export const useUsers = () => useContext(UsersContext);

export function UsersProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentUser } = useAuth();

  const bind = () => {
    setUsers([]);

    if (!currentUser) return;

    setLoading(true);

    onSnapshot(
      collection(db, 'users'),
      (querySnapshot) => {
        const usersData = [];
        querySnapshot.forEach((doc) => {
          usersData.push({ ...doc.data(), id: doc.id });
        });
        setUsers(usersData);
        setLoading(false);
      },
    );
  };

  useEffect(() => {
    bind();
  }, [currentUser]);

  const getUserById = (uid) => users.find((u) => u.id === uid);

  const value = useMemo(
    () => ({
      users,
      loading,
      getUserById,
    }),
    [users, loading],
  );

  return (
    <UsersContext.Provider value={value}>
      {children}
    </UsersContext.Provider>
  );
}
