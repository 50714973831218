import React from 'react';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { audFormatter, priceFormatter } from '../formatters';

function UserTrades({ user, trades }) {
  if (!trades.length) {
    return (
      <div className="trades">
        <span>No data.</span>
      </div>
    );
  }

  return (
    <div className="trades">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Price in</TableCell>
            <TableCell>Price out</TableCell>
            <TableCell>Profit</TableCell>
            <TableCell>Commission</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trades.map((trade) => (
            <TableRow key={trade.id}>
              <TableCell>
                {trade.trade_type}
              </TableCell>
              <TableCell>
                {priceFormatter.format(trade.price_out)}
              </TableCell>
              <TableCell>
                {priceFormatter.format(trade.price_out)}
              </TableCell>
              <TableCell>
                <b>{audFormatter.format(trade.gross_profit)}</b>
              </TableCell>
              <TableCell>
                {audFormatter.format(trade.gross_commission)}
              </TableCell>
              <TableCell>{dayjs(trade.date_out.toDate()).format('DD/MM/YYYY hh:mm a')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default UserTrades;
