import React from 'react';

function Gauge({ value, max }) {
  const percentage = (value / max) * 100;

  return (
    <div className="gauge">
      <div className="gauge-fill" style={{ width: `${percentage}%` }} />
    </div>
  );
}

export default Gauge;
