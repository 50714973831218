import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import LineGraph from './LineGraph';
import { useTrades } from '../contexts/TradesContext';

function UserGraphs({ user, trades }) {
  const { rangeFilter } = useTrades();

  if (!trades.length) {
    return (
      <div className="graphs">
        <span>No data.</span>
      </div>
    );
  }

  const dates = useMemo(() => {
    const dateArray = [];
    let currentDate = dayjs(rangeFilter[0]);
    const stopDate = dayjs(rangeFilter[1]);
    while (currentDate <= stopDate) {
      dateArray.push(dayjs(currentDate).format('DD/MM/YYYY'));
      currentDate = dayjs(currentDate).add(1, 'days');
    }
    return dateArray;
  }, [rangeFilter]);

  const groups = useMemo(() => {
    const obj = dates.reduce((acc, date) => {
      acc[date] = [];
      return acc;
    }, {});

    return trades.reduce((acc, trade) => {
      const date = dayjs(trade.date_in).format('DD/MM/YYYY');
      acc[date].push(trade);
      return acc;
    }, obj);
  }, [trades]);

  const data = useMemo(() => Object.keys(groups).map((date) => ({
    date,
    value: groups[date].reduce((acc, trade) => acc + trade.gross_profit, 0),
  })), [trades]);

  return (
    <div className="graphs">
      <LineGraph data={data} />
    </div>
  );
}

export default UserGraphs;
