import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Button, DateRangePicker } from 'rsuite';

import { useNavigate } from 'react-router-dom';
import { useTrades } from '../contexts/TradesContext';
import { useUsers } from '../contexts/UsersContext';

import UserPerformanceCard from './UserPerformanceCard';
import StockExchangeMap from './StockExchangeMap';

function DashboardAdmin() {
  const {
    trades,
    loading,
    setRangeFilter,
    getTradesForUserWithId,
  } = useTrades();

  const { users } = useUsers();

  const navigate = useNavigate();
  const goToAddTrade = () => {
    navigate('/add-trade');
  };

  const data = useMemo(
    () => users.reduce((acc, user) => {
      acc[user.id] = {
        user,
        trades: getTradesForUserWithId(user.id),
      };

      return acc;
    }, {}),
    [users, trades],
  );

  const content = () => {
    if (loading) {
      return <span>Loading...</span>;
    }

    return (
      <div className="grid">
        {Object.keys(data).map((uid) => (
          <UserPerformanceCard
            user={data[uid].user}
            trades={data[uid].trades}
            key={uid}
          />
        ))}
      </div>
    );
  };

  return (
    <Box sx={{ p: 5 }} className="dashboard">
      <header>
        <DateRangePicker
          onChange={setRangeFilter}
          isoWeek
          placeholder="Select Date Range"
          cleanable={false}
        />

        <Button appearance="primary" onClick={goToAddTrade}>
          Add trade
        </Button>
      </header>

      <hr />

      {content()}

      <StockExchangeMap />
    </Box>
  );
}

export default DashboardAdmin;
