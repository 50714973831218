import React from 'react';
import Map from 'react-map-gl';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import clsx from 'clsx';
import mapboxgl from 'mapbox-gl';

import ExchangeMarker from './ExchangeMarker';

// added the following 6 lines.

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

dayjs.extend(utc);
dayjs.extend(timezone);

const stockExchanges = [
  {
    id: '1',
    title: 'New York Stock Exchange',
    MIC: 'XNYS',
    marketPlace: 'New York',
    timeZone: 'America/Detroit',
    openTime: '09:30',
    closeTime: '16:00',
    lunch: false,
    latitude: 40.7069,
    longitude: -74.0113,
  },
  {
    id: '2',
    title: 'NASDAQ',
    MIC: 'XNAS',
    marketPlace: 'New York',
    timeZone: 'America/Detroit',
    openTime: '09:30',
    closeTime: '16:00',
    lunch: false,
    latitude: 40.7128,
    longitude: -74.0060,
  },
  {
    id: '3',
    title: 'London Stock Exchange',
    MIC: 'XLON',
    marketPlace: 'London',
    timeZone: 'Europe/London',
    openTime: '08:00',
    closeTime: '16:30',
    lunch: false,
    latitude: 51.5145,
    longitude: -0.0900,
  },
  {
    id: '4',
    title: 'Tokyo Stock Exchange',
    MIC: 'XTKS',
    marketPlace: 'Tokyo',
    timeZone: 'Asia/Tokyo',
    openTime: '09:00',
    closeTime: '15:00',
    lunch: true,
    latitude: 35.6762,
    longitude: 139.7600,
  },
  {
    id: '5',
    title: 'Shanghai Stock Exchange',
    MIC: 'XSHG',
    marketPlace: 'Shanghai',
    timeZone: 'Asia/Shanghai',
    openTime: '09:30',
    closeTime: '15:00',
    lunch: true,
    latitude: 31.2304,
    longitude: 121.4737,
  },
  {
    id: '6',
    title: 'Hong Kong Stock Exchange',
    MIC: 'XHKG',
    marketPlace: 'Hong Kong',
    timeZone: 'Hongkong',
    openTime: '09:30',
    closeTime: '16:00',
    lunch: true,
    latitude: 22.2806,
    longitude: 114.1592,
  },
  {
    id: '7',
    title: 'Euronext',
    MIC: 'XPAR',
    marketPlace: 'Paris',
    timeZone: 'CET',
    openTime: '09:00',
    closeTime: '17:30',
    lunch: false,
    latitude: 48.8566,
    longitude: 2.3522,
  },
  {
    id: '8',
    title: 'Toronto Stock Exchange',
    MIC: 'XTSE',
    marketPlace: 'Toronto',
    timeZone: 'America/Toronto',
    openTime: '09:30',
    closeTime: '16:00',
    lunch: false,
    latitude: 43.6532,
    longitude: -79.3832,
  },
  {
    id: '9',
    title: 'Bombay Stock Exchange',
    MIC: 'XBOM',
    marketPlace: 'Mumbai',
    timeZone: 'Asia/Kolkata',
    openTime: '09:15',
    closeTime: '15:30',
    lunch: false,
    latitude: 18.9321,
    longitude: 72.8373,
  },
  {
    id: '10',
    title: 'Australian Securities Exchange',
    MIC: 'XASX',
    marketPlace: 'Sydney',
    timeZone: 'Australia/Sydney',
    openTime: '10:00',
    closeTime: '16:00',
    lunch: false,
    latitude: -33.8704,
    longitude: 151.2080,
  },
  {
    id: '11',
    title: 'Deutsche Börse',
    MIC: 'XETR',
    marketPlace: 'Frankfurt',
    timeZone: 'Europe/Brussels',
    openTime: '09:00',
    closeTime: '17:30',
    lunch: false,
    latitude: 50.1125,
    longitude: 8.6816,
  },
  {
    id: '12',
    title: 'SIX Swiss Exchange',
    MIC: 'XSWX',
    marketPlace: 'Zurich',
    timeZone: 'Europe/Zurich',
    openTime: '09:00',
    closeTime: '17:30',
    lunch: false,
    latitude: 47.3769,
    longitude: 8.5417,
  },
  {
    id: '13',
    title: 'Johannesburg Stock Exchange',
    MIC: 'XJSE',
    marketPlace: 'Johannesburg',
    timeZone: 'Africa/Johannesburg',
    openTime: '09:00',
    closeTime: '17:00',
    lunch: false,
    latitude: -26.2050,
    longitude: 28.0498,
  },
  {
    id: '14',
    title: 'B3',
    MIC: 'BVMF',
    marketPlace: 'São Paulo',
    timeZone: 'America/Sao_Paulo',
    openTime: '10:00',
    closeTime: '17:00',
    lunch: false,
    latitude: -23.5505,
    longitude: -46.6333,
  },
  {
    id: '15',
    title: 'Korea Exchange',
    MIC: 'XKRX',
    marketPlace: 'Seoul',
    timeZone: 'Asia/Seoul',
    openTime: '09:00',
    closeTime: '15:30',
    lunch: true,
    latitude: 37.5131,
    longitude: 126.9743,
  },
  {
    id: '16',
    title: 'Taiwan Stock Exchange',
    MIC: 'XTAI',
    marketPlace: 'Taipei',
    timeZone: 'Asia/Taipei',
    openTime: '09:00',
    closeTime: '13:30',
    lunch: false,
    latitude: 25.0330,
    longitude: 121.5654,
  },
  {
    id: '17',
    title: 'Bolsa de Madrid',
    MIC: 'XMAD',
    marketPlace: 'Madrid',
    timeZone: 'Europe/Madrid',
    openTime: '09:00',
    closeTime: '17:30',
    lunch: false,
    latitude: 40.4168,
    longitude: -3.7038,
  },
  {
    id: '18',
    title: 'Mexican Stock Exchange',
    MIC: 'XMEX',
    marketPlace: 'Mexico City',
    timeZone: 'America/Mexico_City',
    openTime: '08:30',
    closeTime: '15:00',
    lunch: false,
    latitude: 19.4326,
    longitude: -99.1332,
  },
  {
    id: '19',
    title: 'Moscow Exchange',
    MIC: 'XMOEX',
    marketPlace: 'Moscow',
    timeZone: 'Europe/Moscow',
    openTime: '10:00',
    closeTime: '18:45',
    lunch: false,
    latitude: 55.7558,
    longitude: 37.6173,
  },
  {
    id: '20',
    title: 'Singapore Exchange',
    MIC: 'XSES',
    marketPlace: 'Singapore',
    timeZone: 'Singapore',
    openTime: '09:00',
    closeTime: '17:00',
    lunch: true,
    latitude: 1.3521,
    longitude: 103.8198,
  }];

function StockExchangeMap({ full = false }) {
  return (
    <div className={clsx('map-container', full && 'full')}>
      <Map
        initialViewState={{
          longitude: 138.62117622220563,
          latitude: -34.92969343630059,
          zoom: 1,
        }}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/jakeamcprime/clhfo5x3k001e01r548nlhtbb"
      >
        {stockExchanges.map((exchange) => (
          <ExchangeMarker
            key={exchange.id}
            exchange={exchange}
          />
        ))}
      </Map>
    </div>
  );
}

export default StockExchangeMap;
