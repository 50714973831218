export const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 5,
});

export const audFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'AUD',
});

export const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'AUD',
});
