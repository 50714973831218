import React, { useCallback, useEffect, useState } from 'react';
import { Marker } from 'react-map-gl';

import dayjs from 'dayjs';
import clsx from 'clsx';

import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(relativeTime);

function ExchangeMarker({ exchange }) {
  const MINUTE_MS = 1000;

  const [isOpen, setIsOpen] = useState(false);
  const [timeLabel, setTimeLabel] = useState('');
  const [countdownLabel, setCountdownLabel] = useState('');

  const check = useCallback(() => {
    const local = dayjs().tz(exchange.timeZone);

    const oArr = exchange.openTime.split(':');
    const open = dayjs().tz(exchange.timeZone)
      .hour(parseInt(oArr[0], 10))
      .minute(parseInt(oArr[1], 10));

    const cArr = exchange.closeTime.split(':');
    const close = dayjs().tz(exchange.timeZone)
      .hour(parseInt(cArr[0], 10))
      .minute(parseInt(cArr[1], 10));

    const _isOpen = dayjs(local).isBetween(open, close, 'minute');
    const _local = dayjs().tz(exchange.timeZone);

    setIsOpen(_isOpen);
    setTimeLabel(_local.format('H:mm:ss'));

    setCountdownLabel(`${_isOpen ? 'Close in' : 'Open in'} ${dayjs.duration(_local.diff(_isOpen ? close : open)).humanize()}`);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      check();
    }, MINUTE_MS);

    check();

    return () => clearInterval(interval);
  }, []);

  return (
    <Marker
      key={exchange.id}
      latitude={exchange.latitude}
      longitude={exchange.longitude}
    >
      <div className={clsx('marker', isOpen ? 'open' : 'close')}>
        <span className="title">{exchange.title}</span>
        <div className="row">
          <span className="local-time">{timeLabel}</span>
          <span className="countdown-time">{countdownLabel}</span>
        </div>
      </div>
    </Marker>
  );
}

export default ExchangeMarker;
