import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBgQr0WCqPa64WJqpQrhA7o4mTAmy5zY2U',
  authDomain: 'amctraders-54c24.firebaseapp.com',
  projectId: 'amctraders-54c24',
  storageBucket: 'amctraders-54c24.appspot.com',
  messagingSenderId: '982621199299',
  appId: '1:982621199299:web:1634018c7d60291a16fb76',
  measurementId: 'G-17VEK4F0F7',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();

export async function signInWithGoogle() {
  try {
    return signInWithPopup(auth, provider);
  } catch (error) {
    return null;
  }
}

export async function signOut() {
  try {
    return auth.signOut();
  } catch (error) {
    return null;
  }
}
