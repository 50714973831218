import React, { useMemo, useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel, InputAdornment, InputLabel,
  MenuItem, OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import {
  DatePicker, Button, Input, InputGroup,
} from 'rsuite';

import {
  collection,
  addDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAuth } from '../contexts/AuthContext';

import { db } from '../firebase';

function TradeForm() {
  const { currentUser, userData } = useAuth();

  const [saving, setSaving] = useState(false);

  const [currencyPair, setCurrencyPair] = useState('AUD/USD');
  const [tradeType, setTradeType] = useState('BUY');
  const [priceIn, setPriceIn] = useState('');
  const [priceOut, setPriceOut] = useState('');
  const [dateIn, setDateIn] = useState(null);
  const [dateOut, setDateOut] = useState(null);
  const [lots, setLots] = useState('');
  const [grossProfit, setGrossProfit] = useState('');

  const navigate = useNavigate();

  const grossCommission = useMemo(
    () => (grossProfit * (userData.commission_percentage / 100)),
    [grossProfit],
  );

  const goToDashboard = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const trade = {
      currency_pair: currencyPair,
      trade_type: tradeType,
      price_in: parseFloat(priceIn),
      price_out: parseFloat(priceOut),
      date_in: dayjs(dateIn).toDate(),
      date_out: dayjs(dateOut).toDate(),
      lots: parseInt(lots, 10),
      gross_profit: parseFloat(grossProfit),
      gross_commission: parseFloat(grossCommission),
      user_id: currentUser.uid,
    };

    setSaving(true);

    addDoc(
      collection(db, 'trades'),
      trade,
    )
      .then(() => {
        goToDashboard();
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 5 }}>
      <FormControl fullWidth sx={{ mb: 1 }}>
        <FormLabel component="legend">Currency Pair</FormLabel>
        <Select
          value={currencyPair}
          onChange={(e) => setCurrencyPair(e.target.value)}
        >
          {/* Forex */}
          <MenuItem value="AUD/USD">AUD/USD</MenuItem>
          <MenuItem value="EUR/USD">EUR/USD</MenuItem>
          <MenuItem value="USD/JPY">USD/JPY</MenuItem>
          <MenuItem value="GBP/USD">GBP/USD</MenuItem>
          <MenuItem value="USD/CHF">USD/CHF</MenuItem>
          <MenuItem value="USD/CAD">USD/CAD</MenuItem>
          <MenuItem value="NZD/USD">NZD/USD</MenuItem>
          {/* Stocks */}
          {/* <MenuItem value="NZD/USD">NZD/USD</MenuItem> */}
        </Select>
      </FormControl>
      <div className="trade-form-container">
        <FormControl fullWidth component="fieldset" sx={{ mb: 1 }}>
          <FormLabel component="legend">Trade type</FormLabel>
          <RadioGroup
            row
            value={tradeType}
            onChange={(e) => setTradeType(e.target.value)}
          >
            <FormControlLabel value="BUY" control={<Radio />} label="BUY" />
            <FormControlLabel value="SELL" control={<Radio />} label="SELL" />
          </RadioGroup>
        </FormControl>
        <div className="row">
          <FormControl sx={{ mb: 1 }}>
            <FormLabel component="legend">IN</FormLabel>
            <InputGroup>
              <InputGroup.Addon>$</InputGroup.Addon>
              <Input value={priceIn} type="number" onChange={setPriceIn} />
            </InputGroup>
            <DatePicker
              format="yyyy-MM-dd HH:mm aa"
              showMeridian
              isoWeek
              ranges={[
                {
                  label: 'Now',
                  value: new Date(),
                },
              ]}
              onChange={setDateIn}
            />
          </FormControl>
          <FormControl sx={{ mb: 1 }}>
            <FormLabel component="legend">OUT</FormLabel>
            <InputGroup>
              <InputGroup.Addon>$</InputGroup.Addon>
              <Input value={priceOut} type="number" onChange={setPriceOut} />
            </InputGroup>
            <DatePicker
              format="yyyy-MM-dd HH:mm aa"
              showMeridian
              isoWeek
              ranges={[
                {
                  label: 'Now',
                  value: new Date(),
                },
              ]}
              onChange={setDateOut}
            />
          </FormControl>
        </div>
      </div>
      <FormControl fullWidth sx={{ mb: 1 }}>
        <FormLabel component="legend">Lots</FormLabel>
        <TextField
          required
          type="number"
          value={lots}
          onChange={(e) => setLots(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ mb: 1 }}>
        <InputLabel htmlFor="outlined-adornment-amount">Gross profit (AUD)</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={grossProfit}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          label="Gross profit (AUD)"
          onChange={(e) => setGrossProfit(e.target.value)}
        />

        <hr />

        <span>
          Commission:
          {' '}
          <b>
            {userData.commission_percentage}
            %
          </b>
        </span>
        <span>
          Personal gross profit:
          {' '}
          <b>
            $
            {grossCommission.toFixed(2)}
          </b>
        </span>
      </FormControl>

      <hr />

      <div>
        <Button type="submit" appearance="primary" disabled={saving}>
          {saving ? 'Saving' : 'Add Trade'}
        </Button>

        {!saving && (
        <Button appearance="subtle" onClick={goToDashboard} style={{ marginLeft: '10px' }}>
          Cancel
        </Button>
        )}
      </div>
    </Box>
  );
}

export default TradeForm;
