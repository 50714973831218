import React, { useMemo, useState } from 'react';
import { Nav, Rate } from 'rsuite';
import Gauge from './Gauge';
import { audFormatter } from '../formatters';
import { useTrades } from '../contexts/TradesContext';
import { getBusinessDatesCount } from '../helpers';
import UserGraphs from './UserGraphs';
import UserTrades from './UserTrades';

function UserPerformanceCard({ user, trades }) {
  const { rangeFilter } = useTrades();

  const [activeKey, setActiveKey] = useState('performance');

  const daysGap = useMemo(
    () => getBusinessDatesCount(rangeFilter[0], rangeFilter[1]),
    [rangeFilter],
  );

  const totalGrossProfit = useMemo(() => (
    trades.reduce((acc, trade) => acc + trade.gross_profit, 0)
  ), [trades]);

  const totalGrossCommission = useMemo(() => (
    trades.reduce((acc, trade) => acc + trade.gross_commission, 0)
  ), [trades]);

  const items = [
    { eventKey: 'performance', label: 'Performance' },
    { eventKey: 'trades', label: 'Trades' },
  ];

  const subcontent = useMemo(() => {
    switch (activeKey) {
      case 'performance':
        return <UserGraphs user={user} trades={trades} />;
      case 'trades':
        return <UserTrades user={user} trades={trades} />;
      default:
        return null;
    }
  }, [activeKey]);

  return (
    <div className="card">
      <div key={user.id} className="content">
        <header>
          <h3 className="username">{user.display_name}</h3>
          <Rate defaultValue={2.3} readOnly />
        </header>

        <div className="row">
          <div className="col">

            <span>
              Goal:
              {' '}
              <b>{audFormatter.format(user.daily_goal * daysGap)}</b>
            </span>
            <span>
              Profit:
              {' '}
              <b>{audFormatter.format(totalGrossProfit)}</b>
            </span>
          </div>
          <div className="col" style={{ alignItems: 'flex-end' }}>
            <span>
              Trades:
              {' '}
              <b>{trades.length}</b>
            </span>
            <span>
              Commission:
              {' '}
              <b>{audFormatter.format(totalGrossCommission)}</b>
            </span>
          </div>
        </div>
        <Gauge value={Math.min(totalGrossCommission / (user.daily_goal * daysGap), 1)} max={1} />
      </div>

      <div className="sub">
        <Nav activeKey={activeKey} onSelect={setActiveKey} appearance="tabs">
          {items.map((item) => (
            <Nav.Item key={item.eventKey} eventKey={item.eventKey}>
              {item.label}
            </Nav.Item>
          ))}
        </Nav>

        {subcontent}
      </div>
    </div>
  );
}

export default UserPerformanceCard;
