import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import { TradesProvider } from './contexts/TradesContext';
import { UsersProvider } from './contexts/UsersContext';

import App from './components/App';
import reportWebVitals from './reportWebVitals';

import 'rsuite/dist/rsuite.min.css';
import './index.scss';
import { IGProvider } from './contexts/IGContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <UsersProvider>
          <IGProvider>
            <TradesProvider>
              <App />
            </TradesProvider>
          </IGProvider>
        </UsersProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
