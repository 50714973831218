import {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import dayjs from 'dayjs';
import {
  collection, onSnapshot, orderBy, query, where,
} from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from './AuthContext';

const TradesContext = createContext();

export const useTrades = () => useContext(TradesContext);

const startOfDay = dayjs().startOf('day').toDate();
const endOfDay = dayjs().endOf('day').toDate();

export function TradesProvider({ children }) {
  const [trades, setTrades] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rangeFilter, setRangeFilter] = useState([startOfDay, endOfDay]);

  const { currentUser, isAdmin, loading: loadingUser } = useAuth();

  const bind = () => {
    setTrades([]);

    if (!currentUser) return;

    setLoading(true);

    const constraints = [
      where('date_in', '>=', dayjs(rangeFilter[0]).startOf('day').toDate()),
      where('date_in', '<=', dayjs(rangeFilter[1]).endOf('day').toDate()),
      orderBy('date_in', 'asc'),
    ];

    if (!isAdmin) {
      constraints.push(where('user_id', '==', currentUser.uid));
    }

    const q = query(
      collection(db, 'trades'),
      ...constraints,
    );

    onSnapshot(
      q,
      (querySnapshot) => {
        const tradesData = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          tradesData.push({
            ...data,
            date_in: dayjs.unix(data.date_in.seconds),
            date_out: dayjs.unix(data.date_out.seconds),
            id: doc.id,
          });
        });
        setTrades(tradesData);
        setLoading(false);
      },
    );
  };

  const getTradesForUserWithId = (uid) => trades.filter((t) => t.user_id === uid);

  useEffect(() => {
    if (!loadingUser) bind();
  }, [currentUser, loadingUser, rangeFilter]);

  const value = useMemo(
    () => ({
      trades,
      loading,
      rangeFilter,
      setRangeFilter,
      getTradesForUserWithId,
    }),
    [trades, loading, rangeFilter],
  );

  return (
    <TradesContext.Provider value={value}>
      {children}
    </TradesContext.Provider>
  );
}
